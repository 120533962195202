<template>
  <div>
    <template v-if="isMobile">
      <mobile-sales-calendar />
    </template>
    <template v-else>
      <desktop-sales-calendar />
    </template>
  </div>
</template>

<script>
  import MobileSalesCalendar from "./mobileSalesCalendar";
  import DesktopSalesCalendar from "./desktopSalesCalendar";

  export default {
    name: "salesCalendar",
    components: {DesktopSalesCalendar, MobileSalesCalendar},
    metaInfo() {
      return {
        title: this.$t('sales.invoices.calendar.title')
      }
    },
    computed: {
      isMobile () {
        return this.$store.state.windowWidth < this.$store.state.mobileScreen
      },
    }
  }
</script>

<style lang="scss">
</style>
